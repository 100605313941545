<template>
  <!--  考试信息-->
  <div class="exercisesType">
    <div style="display: flex; justify-content: space-between">
      <div class="menu_select">
        <h4 style="padding: 10px">模块</h4>
        <a-menu style="width: 100%" mode="inline" v-model="menu">
          <a-menu-item
            v-for="(item, index) in menuList"
            :key="index"
            @click="leftChange(item.id, item.name)"
            >{{ item.name }}</a-menu-item
          >
        </a-menu>
      </div>
      <div class="InfoTable">
        <p
          style="
            padding: 10px 0px 2px 16px;
            font-weight: 550;
            font-size: 15px;
            color: black;
          "
        >
          用户添加笔记记录
        </p>
        <div class="top_cont">
          <div class="EduRec-top-input">
            <span>日期：</span>
            <a-range-picker
              v-model="datePicker"
              style="margin-right: 15px; width: 220px"
              @change="onChange"
            />
          </div>
          <div class="EduRec-top-input">
            <a-button style="margin-right: 20px" @click="excel">
              <a-icon style="color: #00a854" type="file-excel" />
              导出该模块
            </a-button>
            <a-button @click="Allexcel">
              <a-icon style="color: #00a854" type="file-excel" />
              导出全部模块
            </a-button>
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="messageList"
          :rowKey="(record) => record.id"
          :pagination="false"
          style="margin: 15px"
        >
          <span
            slot="title111"
            slot-scope="text, record, index"
            class="ellipsis"
          >
            <a-tooltip placement="topLeft">
              <template slot="title"> {{ record.questionName }} </template>
              <a @click="questionClick(record)">{{ record.questionName }}</a>
            </a-tooltip>
          </span>
          <span slot="name" slot-scope="text, record, index">
            <a @click="userClick(record)">{{ record.memberName }}</a>
          </span>
          <span
            slot="content"
            slot-scope="text, record, index"
            class="ellipsis"
          >
            <a-tooltip placement="topLeft">
              <template slot="title"> {{ record.content }} </template>
              {{ record.content }}
            </a-tooltip>
          </span>
          <span
            slot="operation"
            slot-scope="text, record, index"
            @click="more1(record)"
          >
            <a>详情</a>
          </span>
        </a-table>
        <div
          style="
            margin-top: 28px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <span
            style="
              font-size: medium;
              color: #929292;
              font-size: 14px;
              bottom: 3%;
            "
          >
            共{{ total }}条记录 第 {{ pageNo }} /
            {{ Math.ceil(total / pageSum) }} 页
          </span>
          <a-pagination
            style="float: right"
            show-quick-jumper
            show-size-changer
            :page-size.sync="pageSum"
            :total="total"
            v-model="pageNo"
            @change="pageChange"
            @showSizeChange="pageChange"
          />
        </div>
      </div>
    </div>
    <!-- 点击详情弹窗 -->
    <a-drawer
      title="详情"
      :width="650"
      :visible="Visible"
      :body-style="{ paddingBottom: '50px' }"
      @close="Cancel"
      style="z-index: 99999"
    >
      <div class="drawerTitle">用户信息</div>
      <div class="navTop">
        <div>姓名：{{ userInfo.memberName }}</div>
        <div>医院：{{ userInfo.hospitalName }}</div>
        <div>科室：{{ userInfo.hospitalOffices }}</div>
        <div>职称：{{ userInfo.jobTitle }}</div>
      </div>
      <div class="drawerTitle" style="margin-top: 24px">笔记信息</div>
      <div class="more">
        <span>关联题目：</span><span>{{ userInfo.questionName }}</span>
      </div>
      <div class="more">
        <span>记录时间：</span><span>{{ userInfo.createdTime }}</span>
      </div>
      <div class="more">
        <span>笔记内容：</span>
        <span>{{ userInfo.content }}</span>
      </div>
    </a-drawer>
    <!-- 点击用户名称弹窗 -->
    <a-drawer
      title="笔记记录"
      :width="850"
      :visible="userVisible"
      :body-style="{ paddingBottom: '50px' }"
      @close="userCancel"
    >
      <div class="drawerTitle">用户信息</div>
      <div class="navTop">
        <div>姓名：{{ userInfo.memberName }}</div>
        <div>医院：{{ userInfo.hospitalName }}</div>
        <div>科室：{{ userInfo.hospitalOffices }}</div>
        <div>职称：{{ userInfo.jobTitle }}</div>
      </div>
      <div class="drawerTitle" style="margin-top: 24px">笔记记录</div>
      <a-table
        :columns="userColumns"
        :data-source="userList"
        :rowKey="(record) => record.id"
        :pagination="false"
        style="margin: 14px 0px"
      >
        <span slot="title111" slot-scope="text, record, index" class="ellipsis">
          <a-tooltip placement="topLeft">
            <template slot="title"> {{ record.questionName }} </template>
            {{ record.questionName }}
          </a-tooltip>
        </span>
        <span slot="content" slot-scope="text, record, index" class="ellipsis">
          <a-tooltip placement="topLeft">
            <template slot="title"> {{ record.content }} </template>
            {{ record.content }}
          </a-tooltip>
        </span>
        <span slot="operation" slot-scope="text, record, index"
          ><a @click="more">详情</a></span
        >
      </a-table>
      <div
        style="
          margin-top: 28px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span
          style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ usertotal }}条记录 第 {{ userpageNo }} /
          {{ Math.ceil(usertotal / userpageSum) }} 页
        </span>
        <a-pagination
          style="float: right"
          show-quick-jumper
          show-size-changer
          :page-size.sync="userpageSum"
          :total="usertotal"
          v-model="userpageNo"
          @change="pageuserChange"
          @showSizeChange="pageuserChange"
        />
      </div>
    </a-drawer>
    <!-- 点击题目弹窗 -->
    <a-drawer
      title="题目记录"
      :width="850"
      :visible="questionVisible"
      :body-style="{ paddingBottom: '50px' }"
      @close="questionCancel"
    >
      <div class="drawerTitle">题目名称</div>
      <div class="navTop">{{ questionName }}</div>
      <div class="drawerTitle" style="margin-top: 24px">笔记记录</div>
      <a-table
        :columns="questionColumns"
        :data-source="questionList"
        :rowKey="(record) => record.id"
        :pagination="false"
        style="margin: 14px 0px"
      >
        <span slot="content" slot-scope="text, record, index" class="ellipsis">
          <a-tooltip placement="topLeft">
            <template slot="title">
              {{ record.content }}
            </template>
            {{ record.content }}
          </a-tooltip>
        </span>
        <span slot="question_operation" slot-scope="text, record, index"
          ><a @click="more">详情</a></span
        >
      </a-table>
      <div
        style="
          margin-top: 28px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span
          style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ questiontotal }}条记录 第 {{ QuespageNo }} /
          {{ Math.ceil(questiontotal / QuespageSum) }} 页
        </span>
        <a-pagination
          style="float: right"
          show-quick-jumper
          show-size-changer
          :page-size.sync="QuespageSum"
          :total="questiontotal"
          v-model="QuespageNo"
          @change="pageQuestionChange"
          @showSizeChange="pageQuestionChange"
        />
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {
  getMoKuaiData,
  getNotesList,
  getUserList,
  getQusetionList,
  getExcel,
  getAllExcel,
} from "@/service/titleExam_api";

export default {
  data() {
    return {
      ellipsis: true,

      id: "",
      total: 0,
      pageNo: 1,
      pageSum: 10,
      startTime: "",
      endTime: "",
      menuList: [],
      menu: [0],
      messageList: [],
      userList: [],
      questionList: [],
      userVisible: false,
      questionVisible: false,
      Visible: false,
      questionName: "",
      userInfo: {},
      datePicker: null,
      ExelName: "",
      usertotal: 0,
      userpageNo: 1,
      userpageSum: 10,
      questiontotal: 0,
      QuespageNo: 1,
      QuespageSum: 10,
      columns: [
        {
          title: "题目名称",
          width: "20%",
          scopedSlots: { customRender: "title111" },
        },
        {
          title: "姓名",
          scopedSlots: { customRender: "name" },
          width: "7%",
        },
        {
          title: "医院",
          dataIndex: "hospitalName",
          width: "15%",
        },
        {
          title: "记录时间",
          dataIndex: "createdTime",
          width: "10%",
        },
        {
          title: "笔记内容",
          //   dataIndex: "content",
          width: "20%",
          scopedSlots: { customRender: "content" },
        },
        {
          title: "操作",
          scopedSlots: { customRender: "operation" },
          width: "7%",
        },
      ],
      userColumns: [
        {
          title: "关联题目",
          width: "20%",
          scopedSlots: { customRender: "title111" },
          //   dataIndex: "questionName",
        },
        {
          title: "记录时间",
          width: "16%",
          dataIndex: "createdTime",
        },
        {
          title: "笔记内容",
          width: "20%",
          //   dataIndex: "content",
          scopedSlots: { customRender: "content" },
        },
        {
          title: "操作",
          width: "7%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      questionColumns: [
        {
          title: "用户名称",
          width: "14%",
          dataIndex: "memberName",
        },
        {
          title: "医院",
          width: "18%",
          dataIndex: "hospitalName",
        },
        {
          title: "记录时间",
          width: "16%",
          dataIndex: "createdTime",
        },
        {
          title: "笔记内容",
          width: "30%",
          //   dataIndex: "content",
          scopedSlots: { customRender: "content" },
        },
        {
          title: "操作",
          width: "9%",
          scopedSlots: { customRender: "question_operation" },
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getMenu();
  },
  methods: {
    onChange(date, dateString) {
      this.startTime = dateString[0];
      this.endTime = dateString[1];
      this.pageNo = 1;
      this.loading();
    },
    async getMenu() {
      let data = {
        page: 1,
        pageSize: 999,
      };
      const res = await getMoKuaiData(data);
      if (res.code == 200) {
        this.menuList = res.data;
        this.load();
      }
    },
    // 默认加载第一个模块内容
    async load() {
      this.id = this.menuList[0].id;
      this.ExelName = this.menuList[0].name;
      let data = {
        examTypeId: this.menuList[0].id,
        pageNo: this.pageNo,
        pageSum: this.pageSum,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      console.log(data);
      const res = await getNotesList(data);
      if (res.code == 0) {
        console.log(res);
        this.messageList = res.data;
        this.total = res.count;
      }
    },
    // 点击左侧菜单获取内容
    async leftChange(id, name) {
      this.id = id;
      this.ExelName = name;
      this.pageNo = 1;
      this.datePicker = null;
      this.startTime = "";
      this.endTime = "";

      let data = {
        examTypeId: this.id,
        pageNo: this.pageNo,
        pageSum: this.pageSum,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      const res = await getNotesList(data);
      if (res.code == 0) {
        this.messageList = res.data;
        this.total = res.count;
      }
    },
    async loading() {
      let data = {
        examTypeId: this.id,
        pageNo: this.pageNo,
        pageSum: this.pageSum,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      const res = await getNotesList(data);
      if (res.code == 0) {
        this.messageList = res.data;
        this.total = res.count;
      }
    },
    userCancel() {
      this.userVisible = false;
    },
    questionCancel() {
      this.questionVisible = false;
    },
    Cancel() {
      this.Visible = false;
    },
    // 分页查询
    pageChange(pageNo, pageSum) {
      this.pageNo = pageNo;
      this.pageSum = pageSum;
      this.loading();
    },
    pageuserChange(userpageNo, userPageSum) {
      this.userpageNo = userpageNo;
      this.userPageSum = userPageSum;
      this.userClick(this.userInfo);
    },
    pageQuestionChange(QuespageNo, QuespageSum) {
      this.QuespageNo = QuespageNo;
      this.QuespageSum = QuespageSum;
      this.questionClick(this.userInfo);
    },

    //点击题目
    async questionClick(record) {
      this.userInfo = record;
      console.log(this.userInfo);
      let data = {
        examTypeId: this.id,
        questionId: this.userInfo.questionId,
        pageNo: this.QuespageNo,
        pageSum: this.QuespageSum,
      };
      const res = await getQusetionList(data);
      if (res.code == 0) {
        this.questionList = res.data;
        this.questionName = res.data[0].questionName;
        this.questionVisible = true;
        this.questiontotal = res.count;
        console.log(this.questiontotal);
      }
    },
    // 点击姓名
    async userClick(record) {
      this.userInfo = record;
      let data = {
        examTypeId: this.id,
        memberId: this.userInfo.memberId,
        pageNo: this.userpageNo,
        pageSum: this.userpageSum,
      };
      const res = await getUserList(data);
      if (res.code == 0) {
        this.userList = res.data;
        this.userVisible = true;
        this.usertotal = res.count;
      }
    },
    // 点击详情
    more() {
      this.Visible = true;
      // this.questionVisible = false;
      // this.userVisible = false
      // if(this.Visible){
      //   this.Visible=false
      // }
      // else{
      //
      // }
    },
    more1(record) {
      this.userInfo = record;
      this.Visible = true;
    },
    async excel() {
      let ExelName = this.ExelName;
      let data = {
        examTypeId: this.id,
        startTime: this.startTime,
        endTime: this.endTime,
        ExelName: ExelName,
      };
      await getExcel(data);
    },
    async Allexcel() {
      let ExelName = "用户添加笔记记录";
      let data = {
        startTime: this.startTime,
        endTime: this.endTime,
        ExelName: ExelName,
      };
      await getAllExcel(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.exercisesType {
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);

  .menu_select {
    background-color: white;
    width: 18%;
    min-height: 750px;
  }
  .InfoTable {
    background-color: white;
    width: 81%;
    min-height: 750px;
  }
}
.drawerTitle {
  font-size: 14px;
  font-weight: 550;
  color: black;
  margin-top: -10px;
}
.navTop {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  margin-top: 10px;
}
.more {
  margin-top: 20px;
}
.top_cont {
  width: 97%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.ellipsis {
  display: block;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>

import {
  get,
  put,
  post,
  del,
  putUserId,
  postUserId,
  delUserId,
  postBId,
  putById,
  delById,
  getDrugExel,
} from "./base";
import qs from "qs";
import { postBuId } from "@/service/base_y";

/**题库管理 **/
/**
 * 时间: 2024-03-11
 * 作者: 韩广志
 * 职称考试分类3.0
 */
export function getExercisesTypeNew(data) {
  return get("/v3/admin/exam/exe/type/select", data);
}
//新增
export function postExercisesTypeNew(data) {
  return postBId("/v3/admin/exam/exe/type/insert", data);
}
//删除
export function delExercisesTypeNew(id) {
  return delById("/v3/admin/exam/exe/type/delete/" + id);
}
//修改
export function putExercisesTypeEdit(data) {
  return putById("/v3/admin/exam/exe/type/update", data);
}
export function getExamLeftNew(data) {
  return get("/v3/admin/question/type/select", data);
}

export function postExamTypeNew(data) {
  return postBId("/v3/admin/exam/type/insert", data);
}
export function putExamTypeNew(data) {
  return putById("/v3/admin/exam/type/update", data);
}
export function delExamTypeNew(id) {
  return delById("/v3/admin/exam/type/delete/" + id);
}
/**
 * 时间: 2024-03-28
 * 作者: 韩广志
 * 获得经验值记录
 */
export function getExpTableDataApi(data) {
  return get('/v3/admin/ex/chart/record/selectByTypeId', data)
}
/**
 * 时间: 2024-03-28
 * 作者: 韩广志
 * 导出明细
 */
export function getExpExportApi (data) {
  return get('/v3/admin/ex/chart/record/exportMingXi', data)
}
/**
 * 时间: 2024-03-28
 * 作者: 韩广志
 * 导出模块
 */
export function getExpExportModeApi (data) {
  return get('/v3/admin/ex/chart/record/exportMock', data)
}
/**
 * 时间: 2024-03-11
 * 作者: 韩广志
 * 职称考试模块管理
 */
export function getMoKuaiData(data) {
  return get("/v3/admin/exam/type/select", data);
}
/**
 * 时间: 2024-03-27
 * 作者: 韩广志
 * 获取职称考试模块管理下的模块
 */
export function getModeInModeApi(typeId) {
  return get("/v3/admin/exam/type/func/selectByTypeId/" + typeId);
}

/**
 * 时间: 2024-03-28
 * 作者: 韩广志
 * 管理模块上移下移
 */
export function putModeUpDownApi(id, type) {
  return put("/v3/admin/exam/type/func/move/" + id + "/" + type);
}

/**
 * 时间: 2024-03-28
 * 作者: 韩广志
 * 删除功能下模块
 */
export function delModeRemoveApi(id) {
  return delById("/v3/admin/exam/type/func/delete/" + id);
}

/**
 * 时间: 2024-03-28
 * 作者: 韩广志
 * 获取全部功能模块信息
 */
export function getModeSelectAll(data) {
  return get("/v3/admin/exam/home/page/func/selectAll", data);
}
/**
 * 时间: 2024-03-28
 * 作者: 韩广志
 * 删除功能
 */
export function delHomeMode(id) {
  return delById("/v3/admin/exam/home/page/func/delete/" + id);
}

/**
 * 时间: 2024-03-28
 * 作者: 韩广志
 * 新增功能模块
 */
export function postHomeModeAdd(data) {
  return postBId("/v3/admin/exam/home/page/func/insert", data);
}

/**
 * 时间: 2024-03-28
 * 作者: 韩广志
 * 修改功能模块
 */
export function putHomeModeEdit(data) {
  return putById("/v3/admin/exam/home/page/func/update", data);
}
/**
 * 时间: 2024-03-28
 * 作者: 韩广志
 * 新增模块功能
 */
export function postModeAddApi(data) {
  return postBId("/v3/admin/exam/type/func/insert", data);
}

//导入题目
export function postExeFile(byName, data) {
  return post("/v3/question/port" + "?byName=" + byName, data);
}

// 修改题干
export function putStem(id, name) {
  return put(`/v3/stem?id=${id}&name=${name}`);
}

//获取题干
export function getTitleStem(pageNum, pageSize, name, id) {
  return get("/v3/stems", { pageNum, pageSize, name, id });
}

//新增复合题型
export function postTitleStem(byName, data) {
  return postBId("/v3/question/stem" + "?byName=" + byName, data);
}
//修改复合题型
export function putTitleStem(id, data) {
  return put("/v3/question/" + id, data);
}
export function postStem(data) {
  return post("/v3/stem", qs.stringify(data));
}

//获取题目列表
export function getTitleExam(tName, pageNum, pageSize) {
  return get("/v3/questiontypes", { tName, pageNum, pageSize });
}

//获取题库管理一级分类列表  HF修改
export function getExamLeft(data) {
  return get("/v3/selQuestionType", data);
}
//获取题目分类数量,用于题目导入  HF修改
export function questionType(data) {
  return get("/v3/question/type/count", data);
}
//获取该分类下的标签和数量  HF修改
export function questionAble(data) {
  return get("/v3/question/able/count", data);
}
//模拟考试按分类导入  HF修改
export function questionPort(data) {
  return get("/v3/question/mock/port", data);
}
//模拟考试按分类导入  HF修改
export function mockPort(data) {
  return post("/v3/question/mock/port", qs.stringify(data));
}
//获取题库管理二级题目列表  HF修改
export function getExamList(data) {
  if (!data.id || data.id === "getExamList") {
    return { data: [], code: 0, count: 0 };
  }
  return get("/v3/selQuestionNew", data);
}

//查询题目
export function getTitleList(tName) {
  return get("/v3/questions", { tName });
}
export function getTitleId(id) {
  return get("/v3/questiontype/" + id);
}
//新增题目分类
export function postTitleType(byName, data) {
  return post("/v3/questiontype" + "?byName=" + byName, data);
}
export function putTitleType(id, data) {
  return put("/v3/questiontype/" + id, data);
}
//新增题目
export function postTitleExam(byName, data) {
  return post("/v3/question" + "?byName=" + byName, data);
}
//修改题目
export function putTitleExam(id, data) {
  return put("/v3/question/" + id, data);
}
//删除题目
export function delTitleExam(id) {
  return del("/v3/question/" + id);
}

/**职称考试分类 **/
export function getExamType(pageNum) {
  return get("/v3/examtypes", { pageNum });
}
export function getExamTypeId(id) {
  return get("/v3/examtype/" + id);
}
export function getTypes(identify) {
  return get("/v3/jobtitles", { identify, pageNum: 1, pageSize: 9999 });
}
//新增
export function postExamType(byName, data) {
  return postUserId("/v3/examtype" + "?byName=" + byName, data);
}
//修改
export function putExamType(id, data) {
  return putUserId("/v3/examtype/" + id, data);
}
export function putExamOpen(id) {
  return put("/v3/examIsOpen/" + id);
}

//删除
export function delExamType(id) {
  return del("/v3/examtype/" + id);
}

// 上架
export function putUpjia(id) {
  return put("/v3/examIsShowOn/" + id);
}

// 下架
export function putdownjia(id) {
  return put("/v3/examIsShowOff/" + id);
}

// 上下架
export function putUpDownjia(id, isShow) {
  return put("/v3/examIsShow/" + id + "?isShow=" + isShow);
}

// export function putUpDownjia(id,data){
//     return put('/v3/examIsShow?id='+id,data)
// }

// export function putUpDownjia(id,data){
//     return put('/v3/examIsShow?id='+ id,data)
// }

// export function putUpDownjia(id,data){
// 	return put(`/v3/examIsShow/id?data=${data}`)
// }

// export function putUpDownjia(id,data){
//     return put('/v3/examIsShow?id='+id)
// }

/**同步练习分类 **/
export function getExercisesType() {
  return get("/v3/exetypes");
}
export function getTitleExamList() {
  return get("/v3/examtypes", { pageNum: 1, pageSize: 9999 });
}
export function getExercisesTypeId(id) {
  return get("/v3/exetype/" + id);
}
export function postExercisesType(byName, data) {
  return postUserId("/v3/exetype" + "?byName=" + byName, data);
}
export function putExercisesType(id, data) {
  return putUserId("/v3/exetype/" + id, data);
}
export function delExercisesType(id) {
  return delUserId("/v3/exetype/" + id);
}

//同步分类上移下移
export function postMoveUpType(id, typeId) {
  return postUserId("/v3/moveUpExeType/" + id + "/" + typeId);
}
export function postMoveDownType(id, typeId) {
  return postUserId("/v3/moveDownExeType/" + id + "/" + typeId);
}

/** 同步练习管理 **/
//获取练习
export function getExercises(pageNum) {
  return get("/v3/exes", { pageNum });
}
export function getExercisesId(id) {
  return get("/v3/exe/" + id);
}
//新增练习
export function postExercises(data) {
  return postBId("/v3/admin/exam/exe/insert", data);
}
//修改练习
export function putExercises(data) {
  return putById("/v3/admin/exam/exe/update", data);
}
//删除练习
export function delExercises(id) {
  return delById("/v3/admin/exam/exe/delete/" + id);
}
//获取题目
export function getExam(id, pageNum, name) {
  return get("/v3/exequestion/" + id, { pageNum, name });
}
export function postExam(exeId, byName, data) {
  return postUserId(
    "/v3/exequestion" + "?exeId=" + exeId + "&byName=" + byName,
    data
  );
}

export function putExerciseMove(id, type) {
  return putById("/v3/admin/exam/exe/upOrDown/" + id + "/" + type);
}

//上移练习
export function postUpExercises(id, eid) {
  return postUserId("/v3/moveUpExe/" + id + "/" + eid);
}
//下移练习
export function postDownExercises(id, eid) {
  return postUserId("/v3/moveDownExe/" + id + "/" + eid);
}
//上移题目
export function postUpExam(id, eid) {
  return postUserId("/v3/moveUpq/" + id + "/" + eid);
}
//下移题目
export function postDownExam(id, eid) {
  return postUserId("/v3/moveDownq/" + id + "/" + eid);
}

/** 模拟练习 **/
// export function getTestList(){
//     return get('/v3/mocks')
// }
//模拟考试获取左侧一级分类列表   HF修改
export function getTestList() {
  return get("/v3/selMockType");
}
export function getTestExam(data) {
  return get("/v3/selMock", data);
}
export function getTestId(id) {
  return get("/v3/mock/" + id);
}
export function postTest(data) {
  return postBId("/v3/admin/exam/mockOrPast/insert", data);
}
export function putTest(data) {
  return putById("/v3/admin/exam/mockOrPast/update", data);
}
//练习上移
export function postTestUp(id, eid) {
  return postUserId("/v3/moveUpMock/" + id + "/" + eid);
}
//练习下移
export function postTestDown(id, tid) {
  return postUserId("/v3/moveDownMoke/" + id + "/" + tid);
}
//获取题目
export function getTextExam(id, pageNum, name) {
  return get("/v3/mockquestions/" + id, { pageNum, name });
}
export function delTest(id) {
  return delById("/v3/admin/exam/mockOrPast/delete/" + id);
}
//上移题目
export function postUpTextExam(id, mid) {
  return postUserId("/v3/moveUpm/" + id + "/" + mid);
}
//下移题目
export function postDownTextExam(id, mid) {
  return postUserId("/v3/moveDownm/" + id + "/" + mid);
}
export function postNewTextExam(exeId, byName, data) {
  return postUserId(
    "/v3/mockquestion" + "?mockId=" + exeId + "&byName=" + byName,
    data
  );
}
//修改分数
export function putNewExamScore(id, data) {
  return putUserId("/v3/score/" + id, qs.stringify(data));
}
export function putExamNumScore(id) {
  return putUserId("/v3/score/z/" + id);
}
//题库批量导入
export function postQuestion(exclFile, byName, code) {
  //接口中就会提示没有
  return post(
    "/v3/question/port?byName=" + byName + "&code=" + code,
    exclFile,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
}

/** 职称考试3.0 --api **/

//获取模板
export function getExamTemplate(page) {
  return get("/v3/admin/exam/type/select", { page });
}

//获取同步练习-模拟考试-历年真题所属分类
//type:同步练习传EXE 模拟考试传MOCK 历年真题传PAST
export function getExamOpenType(type, typeId) {
  return get("/v3/admin/exam/exe/type/selectOpenType", { type, typeId });
}
//获取同步练习
export function getExeExamListNew(type, exeType, page, pageSize) {
  return get("/v3/admin/exam/exe/select", { type, exeType, page, pageSize });
}

//模拟练习/历年真题
// board: MOCK：模拟考试 PAST：历年真题
export function getMockExamListNew(data) {
  return get("/v3/admin/exam/mockOrPast/select", data);
}
//移动模拟练习/历年真题
export function putMoveMockExam(id, type) {
  return put("/v3/admin/exam/mockOrPast/upOrDown/" + id + "/" + type);
}
// 考点精选 李凌
// 推荐
export function postRecommend(data) {
  return postBId("/v3/h5/exam/recommend", data);
}
// 下架
export function putRelease(id, isRelease) {
  return put(`/v3/h5/exam/choiceness/release?id=${id}&isRelease=${isRelease}`);
}
// 修改
export function putChoiceness(data) {
  return put("/v3/h5/exam/choiceness", data);
}
// 新增考试精选
export function postChoiceness(data) {
  return postBId("/v3/h5/exam/choiceness", data);
}
// 查询考点精选
export function getExamChoiceness(data) {
  return get("/v3/h5/exam/choiceness", data);
}
// 置顶
export function putTop(id) {
  return put(`/v3/h5/exam/choiceness/top?id=${id}`);
}
// 考试信息  邓换媚
// 查询考试信息
export function getExamMessage(data) {
  return get("/v3/h5/exam/message", data);
}
// 新增考试信息
export function postExamMessage(data) {
  return postBId("/v3/h5/exam/message", data);
}
// 上架和下架考试信息
export function putExamMessage(id, isRelease) {
  return put(`/v3/h5/exam/message/release?id=${id}&isRelease=${isRelease}`);
}
// 置顶和取消置顶考试信息
export function putExamMessageTop(id) {
  return put(`/v3/h5/exam/message/top?id=${id}`);
}
// 推荐内容
export function postRecommendMessage(data) {
  return postBId("/v3/h5/exam/recommend", data);
}
//修改考试信息
export function editMessage(data) {
  return put("/v3/h5/exam/message", data);
}

/**
 * 时间: 2024-03-26
 * 作者: 冯明霞
 * 职称考试分类3.0
 * 同步练习使用记录  模拟考试使用记录  历年真题使用记录  每日一练使用记录  错题添加记录
 */

/**
 * 获取左侧模块菜单
 * @param data
 * @returns {Promise<* | void>}
 */
export function getModuleMenu(data) {
  return get("/v3/admin/exam/type/select", data);
}

/**
 * 同步练习-用户每日完成题目数量
 * @param data
 * @returns {Promise<* | void>}
 */
export function getNumberQuestionsDay(data) {
  return get("/v3/log/exam/syncExam/user", data);
}
/**
 * 同步练习记录-每日使用人数
 * @param data
 * @returns {Promise<* | void>}
 */
export function getNumberUsersSynchronousExercises(data) {
  return get("/v3/log/exam/syncExam/day", data);
}
/**
 * 导出-同步练习-用户每日完成题目数量
 * @param data
 * @returns {Promise<unknown>}
 */
export function NumberQuestionsDayExport(data) {
  return getDrugExel("/v3/log/exam/syncExam/user/export", data);
}
/**
 * 导出-同步练习记录-每日使用人数
 * @param data
 * @returns {Promise<unknown>}
 */
export function syncExamExport(data) {
  return getDrugExel("/v3/log/exam/syncExam/day/export", data);
}

/**
 * 模拟考试/历年真题-用户每日完成数量
 * @param data
 * @returns {Promise<* | void>}
 */
export function getMockUser(data) {
  return get("/v3/log/exam/mock/user", data);
}
/**
 * 模拟考试/历年真题-每日完成用户数
 * @param data
 * @returns {Promise<* | void>}
 */
export function getExamMockDay(data) {
  return get("/v3/log/exam/mock/day", data);
}
/**
 * 导出-模拟考试/历年真题-每日完成用户数
 * @param data
 * @returns {Promise<unknown>}
 */
export function examMockDayExport(data) {
  return getDrugExel("/v3/log/exam/mock/day/export", data);
}
/**
 * 导出-模拟考试/历年真题-用户每日完成数量
 * @param data
 * @returns {Promise<unknown>}
 */
export function examMockDayUserExport(data) {
  return getDrugExel("/v3/log/exam/mock/user/export", data);
}

/**
 * 用户每日一练记录
 * @param data
 * @returns {Promise<* | void>}
 */
export function getExamExerciseDay(data) {
  return get("/v3/log/exam/exercise/day", data);
}

/**
 * 导出-用户每日一练记录
 * @param data
 * @returns {Promise<unknown>}
 */
export function examExerciseDayExport(data) {
  return getDrugExel("/v3/log/exam/exercise/day/export", data);
}

/**
 * 错题添加记录-用户每日添加错题数量
 * @param data
 * @returns {Promise<* | void>}
 */
export function getMistakesAddUser(data) {
  return get("/v3/log/exam/errQuestion/user", data);
}
/**
 * 错题添加记录-错题每日被添加数量
 * @param data
 * @returns {Promise<* | void>}
 */
export function getMistakesAddDay(data) {
  return get("/v3/log/exam/errQuestion/question", data);
}
/**
 * 导出-错题添加记录-用户每日添加错题数量
 * @param data
 * @returns {Promise<unknown>}
 */
export function MistakesAddUserExport(data) {
  return getDrugExel("/v3/log/exam/errQuestion/user/export", data);
}
/**
 * 导出-错题添加记录-错题每日被添加数量
 * @param data
 * @returns {Promise<unknown>}
 */
export function MistakesAddDayExport(data) {
  return getDrugExel("/v3/log/exam/errQuestion/question/export", data);
}

//题库新增分类
export function postTitleTypeNew(data) {
  return postBId("/v3/admin/question/type/insert", data);
}
//题库修改分类
export function putTitleTypeNew(data) {
  return putById("/v3/admin/question/type/update", data);
}

/**
 * 时间: 2024-03-28
 * 作者: 李凌
 * 职称考试分类3.0
 * 用户纠错记录
 */
// 查询考试信息
export function getErrSelectAll(data) {
  return get("/v3/admin/que/err/cor/selectAll", data);
}
// 根据题目名称查询
export function getselectAllByQuestionId(questionId,page,pageSum) {
  return get("/v3/admin/que/err/cor/selectAllByQuestionId?questionId="+questionId + "&page=" + page + "&pageSum=" + pageSum);
}
// 根据名字查询
export function getselectAllByMemberId(createdBy,page,pageSum) {
  return get("/v3/admin/que/err/cor/selectAllByMemberId?createdBy="+createdBy + "&page=" + page + "&pageSum=" + pageSum);
} 
// 获取纠错记录详情  
export function getSelectById(id) {
  return get("/v3/admin/que/err/cor/selectById?id="+id);
} 
// 回复  
export function putReply(data) {
  return putById("/v3/admin/que/err/cor/reply", data);
}
// 导出纠错模块   
export function exportQuestionErrorCorrectionDataToExcel(data) {
  return getDrugExel("/v3/admin/que/err/cor/exportQuestionErrorCorrectionDataToExcel",data);
} 
// 推荐内容管理 邓换媚
// 查询推荐内容列表
export function getRecommendList(data) {
  return get("/v3/exam/recommend", data);
}
// 上移下移
export function moveRecommendList(id, move) {
  return put(`/v3/exam/recommend/move?id=${id}&move=${move}`);
}
// 取消推荐
export function delRecommendList(id) {
  return del(`/v3/exam/recommend?id=${id}`);
}

// 查询笔记记录内容列表 邓换媚
// 查询笔记记录;
export function getNotesList(data) {
  return get("/v3/exam/note/log", data);
}
// 后台-通过题目查询笔记记录
export function getQusetionList(data) {
  return get("/v3/exam/note/log/question", data);
}
// 后台-通过姓名查询笔记记录
export function getUserList(data) {
  return get("/v3/exam/note/log/member", data);
}
// 导出该模块
export function getExcel(data) {
  return getDrugExel("/v3/exam/note/log/module/excel", data);
}
// 导出全部模块

export function getAllExcel(data) {
  return getDrugExel("/v3/exam/note/log/module/all/excel", data);
}



// 获取题干列表
export function getQuestionSelectAll(data) {
  return get("/v3/admin/question/stem/selectAll", data);
}

// 新增题干
export function postQuestionSelectAll(data) {
  return postBId("/v3/admin/question/stem/insert", data);
}

// 修改题干
export function putQuestionSelectAll(data) {
  return putById("/v3/admin/question/stem/update", data);
}

// 删除题干
export function delQuestionSelectAll(id) {
  return delById("/v3/admin/question/stem/delete/"+id);
}

// 获取题干关联题目详情
export function getQuestionSelect(id,data) {
  return get("/v3/admin/question/stem/selectDetails/"+id,data);
}

// 考试信息浏览记录
export function getMessageRecord(data) {
  return get('/v3/exam/message/log',data)
}
// 导出该模块--考试信息
export function getMseeageExcel(data) {
  return getDrugExel("/v3/exam/message/log/module/excel",data);
}
// 导出全部模块---考试信息
export function getAllMessageExcel(data) {
  return getDrugExel("/v3/exam/message/log/module/all/excel",data);
}
// 考点精选浏览记录
export function getPonitsRecord(data) {
  return get("/v3/exam/choiceness/log",data);
}
// 导出该模块--考点精选
export function getPointsExcel(data) {
  return getDrugExel('/v3/exam/choiceness/log/module/excel', data)
}
// 导出全部模块---考点精选
export function getAllPointsExcel(data) {
  return getDrugExel("/v3/exam/choiceness/log/module/all/excel",data);
}
